import dataUriToObjectUrl from './data-uri-to-object-url';
import { component, html } from './deps';
import post from './post';

export default class About extends component {
    static tag = 'x-about';

    brochureObjectUrl = '';
    speakerImageObjectUrl = '';

    global = {
        year: '',
        month: '',
        brochure: '',
        conferenceDay: '',
        galaDay: '',
        galaLocation: '',
        galaLink: '',
        speakerTitle: '',
        speakerBio: '',
        speakerImage: '',
    };

    async connected() {
        this.global = await post('/global');
        this.brochureObjectUrl = await dataUriToObjectUrl(this.global.brochure);
        this.speakerImageObjectUrl = await dataUriToObjectUrl(this.global.speakerImage);
    }

    render = () => html`

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Events</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white">Conference Healthy Fair and Gala.</p>
            </div>
        </div>

        <div class="container padding-xs">

            <div class="flex-justify padding-xs">
                <div class="flex flex-column padding-s">
                    <img width="300" src="/bucket/global/speaker" alt="speaker image" />
                </div>
                <div class="flex flex-1 flex-column justify-content-center">
                    <h2>Conference and Healthy Fair ${this.global.month} ${this.global.conferenceDay}, ${this.global.year}</h2>
                    <p>The Showcase of Tucson Nurses is a sharing educational conference where southern Arizona Nurses exchange innovative health care ideas during a two-part continuing education offering.</p>
                    <p>As a part of the Nurses Week Celebration, this program is a tribute to southern Arizona Nurses who respond daily to the health care needs of the community.</p>
                    <p>Annually more than two hundred Nurses from southern Arizona, educators, staff Nurses, administrators, clinical nurse specialists, nurse practitioners, researchers, and other practicing professionals from a variety of disciplines, attend the Showcase to learn about current Nursing research and practice, and network with their colleagues.</p>
                    <p>The focus of the Healthy Fair and Conference is to encourage Nurses to explore and experience varied ways of caring for themselves and their own families through education and other modalities. It is also an opportunity for Nurses to share special interests with their peers through arts, self-care, and products. Vendors often display jewelry, uniforms, drug information, vaccine information, scholarly publications, massage therapy, and many other interesting, informative, and fun exhibits for Nurses during the annual conference.</p>
                    <a class="button button-primary" href="/registration/conference">Registration</a>
                </div>
            </div>

            <div class="flex-justify padding-xs">
                <div class="flex flex-column padding-s">
                    <img width="300" src="opening-keynote-speaker-profile.jpg" alt="speaker image" />
                </div>
                <div class="flex flex-1 flex-column justify-content-center">
                    <h2>Opening Keynote Speaker</h2>
                    <p>Jacqueline J. Medland Ph.D., R.N., NEA-BC</p>
                    <p>From Idea to Impact: The 'I' in Innovation</p>
                    <p><a class="button" href="/opening-keynote-speaker-bio.pdf" target="_blank">Speaker BIO</a>
                </div>
            </div>

            <div class="flex-justify padding-xs">
                <div class="flex flex-column padding-s">
                    <img width="300" src="https://res.cloudinary.com/dbc2wlvk8/image/upload/c_scale,fl_any_format.lossy,w_400/v1477689320/TNWF/gala.jpg" alt="gala event image" />
                </div>
                <div class="flex flex-1 flex-column justify-content-center">
                    <h2>Nurses Week Gala ${this.global.month} ${this.global.galaDay}, ${this.global.year}</h2>
                    <p>A formal dance celebrating Nursing, honoring Tucson Fabulous 50 Nurses and presenting the recipient of the annual Clinical Nursing Practice Project Award. Enjoy celebrating, dancing and socializing throughout the evening.</p>
                    <p>
                        <strong>Date: </strong><span>${this.global.month} ${this.global.galaDay}, ${this.global.year}</span><br>
                        <strong>Group Rates Available: </strong><span>${this.global.month} ${this.global.galaDay - 3}, ${this.global.year} - ${this.global.month} ${this.global.galaDay + 3}, ${this.global.year}</span><br>
                        <strong>Guest Room Rates: </strong><span>$159 per night. Day of event complementary self parking or valet parking. Must be booked by April 10th 2025.</span><br>
                        <strong>Discounts: </strong><span>10% off spa. Waived resort fees. Special green fees.</span><br>
                        <strong>Location: </strong><span>${this.global.galaLocation}</span><br>
                    </p>
                    <p>All reservations must be guaranteed with a valid credit card. This card will be charged in the event of a cancellation, no-show or deposit when warranted. Group rates are subject to availability.</p>
                    <p>Hotel cancellation policy requires guests to cancel by 4pm local time 72 hours before arrival to avoid a penalty fee of 1 nights' room and tax.</p>
                    <p>Guests can also call to make reservations at +1 (800) 235-6397, and mention the Tucson Nurses Week</p>
                    <p>For special TNWF pricing use this link <a class="button" href="${this.global.galaLink}" target="_blank">Reserve</a></p>
                    <a class="button button-primary" href="/registration/gala">Gala</a>
                </div>
            </div>

        </div>

    `

}
