import { component, html } from './deps';

export default class Header extends component {
    static tag = 'x-header';

    render = () => html`
        <div class="flex-justify padding-m">
            <div class="logo flex flex-column">
                <object type="image/svg+xml" data="./logo.svg">Tucson Nurses Week Foundation Logo</object>
            </div>
            <div class="flex flex-column justify-content-center">
                <nav class="nav-inline">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/events">Events</a></li>
                        <li><a href="/sponsor">Sponsor</a></li>
                        <li><a href="/donate">Donate</a></li>
                        <li><a href="/past-awardees">Past Awardees</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    `
}