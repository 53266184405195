export const date = new Date();

export const year = date.getFullYear();

export const month = date.getMonth() + 1;

export const years = ((): number[] => {
    const values: number[] = [];

    let count = 2023;
    const current = year;

    while (count <= current) {
        values.push(count);
        count++;
    }

    return values;
})();