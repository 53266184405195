import dataUriToObjectUrl from './data-uri-to-object-url';
import { Form } from '../database/form';
import { component, html } from './deps';
import base64 from './base64';
import post from './post';

export default class Admin extends component {
    static tag = 'x-admin';

    forms: Form[] = [];

    speakerUrl = '/bucket/global/speaker';
    brochureUrl = '/bucket/global/brochure';

    global = {
        brochure: '',
        year: 0,
        month: 0,
        conferenceDay: 0,
        galaDay: 0,
        galaLocation: '',
        galaLink: '',
        speakerTitle: '',
        speakerBio: '',
        speakerImage: '',
    };

    async speakerInput (e) {
        this.global.speakerImage = await base64(e.target.files?.[ 0 ])
        this.speakerUrl = await dataUriToObjectUrl(this.global.speakerImage);
    }

    async brochureInput (e) {
        this.global.brochure = await base64(e.target.files?.[ 0 ]);
        this.brochureUrl = await dataUriToObjectUrl(this.global.brochure);
    }

    async globalSave(e) {
        e.preventDefault();
        await post('/admin/global/update', this.global);
    }

    async connected() {
        this.forms = await post('/admin/form/search');
        this.global = await post('/admin/global/view');
    }

    render = () => html`
    <div class="container">

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Admin Dashboard</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white"></p>
            </div>
        </div>

        <div class="flex flex-column padding-m">
            <div class="flex flex-row">
                <h1>Actions</h1>
            </div>
            <div class="flex flex-row">
                <a class="button margin-xs" href="/admin/awardees">
                    <span class="material-symbols-outlined">award_star</span>
                    <span>Awardees Manager</span>
                </a>
                <a class="button margin-xs" href="/admin/sponsors">
                    <span class="material-symbols-outlined">groups</span>
                    <span>Sponsors Manager</span>
                </a>
                <a class="button margin-xs" href="/admin/form">
                    <span class="material-symbols-outlined">add</span>
                    <span>New Form</span>
                </a>
            </div>
        </div>

        <div class="flex flex-column padding-m">
            <h1>Forms</h1>
            <div class="table">
                <div class="font-weight-bold">
                    <span>Form Name</span>
                    <span>Form Path</span>
                    <span>Action</span>
                </div>
                ${this.forms.map(form => html`
                <div>
                    <span>${form.name}</span>
                    <span>${form.path}</span>
                    <span><a href=${'/admin/form?id=' + form.id} class="button button-border button-primary">View</a></span>
                </div>
                `)}
            </div>
        </div>

        <div class="padding-m">
            <h1>Global Data</h1>
            <form class="" onsubmit='${this.globalSave}'>
                <div class="flex flex-row">
                    <p class="field">
                        <label for="year">year</label>
                        <input value="${this.global.year}" oninput=${e => this.global.year = e.target.valueAsNumber} type="number" id="year" placeholder="Enter Year" />
                    </p>
                    <p class="field">
                        <label for="month">month</label>
                        <input value="${this.global.month}" oninput=${e => this.global.month = e.target.value} type="text" id="month" placeholder="Enter month" />
                    </p>
                    <p class="field">
                        <label for="conferenceDay">Conference day</label>
                        <input value="${this.global.conferenceDay}" oninput=${e => this.global.conferenceDay = e.target.valueAsNumber} type="number" id="conferenceDay" placeholder="Enter day" />
                    </p>
                    <p class="field">
                        <label for="galaDay">Gala day</label>
                        <input value="${this.global.galaDay}" oninput=${e => this.global.galaDay = e.target.valueAsNumber} type="number" id="galaDay" placeholder="Enter day" />
                    </p>
                </div>
                <div class="flex flex-row">

                    <p class="field">
                        <label for="galaLocation">Gala location</label>
                        <input value="${this.global.galaLocation}" oninput=${e => this.global.galaLocation = e.target.value} type="text" id="galaLocation" placeholder="Enter location" />
                    </p>
                    <p class="field">
                        <label for="galaLink">Gala special pricing link</label>
                        <input value="${this.global.galaLink}" oninput=${e => this.global.galaLink = e.target.value} type="text" id="galaLink" placeholder="Enter Link" />
                    </p>
                </div>
                 <div class="flex flex-row">

                    <p class="field">
                        <label for="speakerTitle">Speaker Title</label>
                        <input value="${this.global.speakerTitle}" oninput=${e => this.global.speakerTitle = e.target.value} type="text" id="speakerTitle" placeholder="Enter speaker title" />
                    </p>
                </div>
                 <div class="flex flex-row">
                    <p class="field">
                        <label for="speakerBio">Speaker Bio</label>
                        <textarea value="${this.global.speakerBio}" oninput=${e => this.global.speakerBio = e.target.value} id="speakerBio" placeholder="Enter speaker bio"></textarea>
                    </p>
                </div>
                <div class="flex flex-auto">
                    <label class="field" >
                        <div>Speaker Image</div>
                        <input class="input" type="file" accept=".png, .jpg, .jpeg, .svg" oninput=${this.speakerInput} />
                        <img src=${this.speakerUrl} class="border border-radius" style="max-height:300px;max-width:300px;" />
                    </label>
                </div>
                <div class="flex flex-auto">
                    <label class="field" >
                        <div>Brochure</div>
                        <input class="input" type="file" accept=".pdf" oninput=${this.brochureInput} />
                        <embed src=${this.brochureUrl} type="application/pdf" class="border border-radius" style="overflow:hidden;max-height:300px;max-width:300px;" />
                    </label>
                </div>
                <button type="submit" class="button button-primary margin-xxs">
                    <span class="material-symbols-outlined">save</span>
                    <span>Save</span>
                </button>
            </form>
        </div>

    </div>
    `
}
