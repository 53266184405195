import { Awardee } from '../database/awardee';
import { component, html } from './deps';
import post from './post';

export default class PastAwardees extends component {
    static tag = 'x-past-awardees';

    years = [
        2023,
        2024,
        2025,
    ];

    awardees: Array<Awardee> = [];

    async connected() {
        this.awardees = await post<Array<Awardee>>('/awardees', {});
    }

    render = () => html`
        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Past Awardees</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white">Alphabetical list of past awardees.</p>
            </div>
        </div>

        ${this.years.map(year => html`
            <div class="container padding-m">
                <div class="flex flex-row">
                    <div class="flex flex-column">
                        <h4 id=${year}>${year}</h4>
                    </div>
                </div>
                <div class="flex flex-row flex-wrap justify-content-space-around">
                    ${this.awardees.filter(a => a.year === year).map(a => html`
                        <div class="flex flex-row justify-content-center align-items-center padding-xxs">
                            <img style="height:5rem;min-width:3.5rem;border-radius:5rem;padding:0.25rem;" src=${'/bucket/awardee/' + a.id} alt=${'awardee ' + a.name} loading="lazy"/>
                            <span>${a.name}</span>
                        </div>
                    `)}
                </div>
            </div>
        `)}

    `;
}
