import { Awardee } from '../database/awardee';
import { component, html } from './deps';
import base64 from './base64';
import post from './post';

export default class AdminAwardees extends component {
    static tag = 'x-admin-awardees';

    awardees: Awardee[] = [];

    async input(e, awardee) {
        const target = e.target as HTMLInputElement;
        const name = target.name;
        const type = target.type;
        let value;
        if (type === 'file') {
            const file = target.files?.[ 0 ];
            (this.querySelector('#preview-'+awardee.id) as any).src = URL.createObjectURL(file as File);
            value = await base64(file);
        } else if (type === 'number') {
            value = target.valueAsNumber;
        } else {
            value = target.value;
        }
        awardee[name] = value;
    }

    async submit(e, awardee) {
        e.preventDefault();
        if (awardee.id) {
            await post('/admin/awardee/update', awardee);
        } else {
            await post('/admin/awardee/create', awardee);
        }
        this.awardees = await post('/admin/awardee/search', { year: this.yearSelected });
    }

    async confirm(awardee) {
        const result = prompt(`Are you sure you want to Remove the Awardee ${awardee.name}?`);
        if (result !== 'yes') return;
        await post('/admin/awardee/remove', awardee);
        this.awardees = await post('/admin/awardee/search');
    }

    add() {
        this.awardees.unshift({
            id: '', updated: 0, created: 0,
            name: '', type: '', year: this.yearSelected, facility: '',
        });
    }

    yearSelected: number = new Date().getFullYear();

    async yearFilter(e) {
        this.yearSelected = Number(e.target.value);
        this.awardees = await post('/admin/awardee/search', { year: this.yearSelected });
    }

    async connected() {
        this.awardees = await post('/admin/awardee/search', { year: this.yearSelected });
    }

    render = () => html`
    <div class="container">

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Admin Awardees Manager</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white"></p>
            </div>
        </div>

        <div class="flex flex-row justify-content-space-between padding-s">
            <div class="font-size-xl">
                <a href="/admin" class="button button-border button-primary">
                    <span class="material-symbols-outlined">dashboard</span>
                    <span>Dashboard</span>
                </a>
            </div>
            <div class="font-size-xl">
                <button class="button" onclick=${this.add}>
                    <span class="material-symbols-outlined">add</span>
                    <span>Add Awardee</span>
                </button>
            </div>
        </div>

        <div class="flex flex-row padding-s">
            <label class="select" for="yearSelect"> Year
                <select id="yearSelect" value=${this.yearSelected} onchange=${e => this.yearFilter(e)}>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </label>
        </div>

        <div class="padding-m">
            ${this.awardees.map(awardee => html`
            <form
                class="flex flex-row flex-wrap align-items-flex-start"
                oninput=${e => this.input(e, awardee)}
                onsubmit=${e => this.submit(e, awardee)}
            >
                <div class="flex flex-column align-items-flex-start">
                    <div class="flex flex-row">
                        <div class="flex flex-column">
                            <label class="field">
                                <div>Name</div>
                                <input class="input" name="name" type="text" value=${awardee.name} required/>
                            </label>
                            <label class="field">
                                <div>Type</div>
                                <select name="type" class="input" value=${awardee.type} required>
                                    <option value="" selected></option>
                                    <option value="alex stuetze">Alex Stuetze</option>
                                    <option value="top 5 awardees">Top 5 Awardees</option>
                                    <option value="fabulous 50 awardees">Fabulous 50 Awardees</option>
                                    <option value="most inspirational mentor">Most Inspirational Mentor</option>
                                </select>
                            </label>
                        </div>
                        <div class="flex flex-column">
                            <label class="field">
                                <div>Year</div>
                                <input class="input" name="year" type="number" value=${awardee.year} required/>
                            </label>
                            <label class="field">
                                <div>Facility</div>
                                <input class="input" name="facility" type="text" value=${awardee.facility} required/>
                            </label>
                        </div>
                    </div>
                    <div class="field flex flex-row">
                        <button class="button success margin-xs" type="submit">
                            <span class="material-symbols-outlined">save</span>
                            <span>Save</span>
                        </button>
                        <button class="button error margin-xs" type="button" onclick=${() => this.confirm(awardee)}>
                            <span class="material-symbols-outlined">delete</span>
                            <span>Remove</span>
                        </button>
                    </div>
                </div>
                <div class="flex flex-auto">
                    <label class="field" >
                        <div>Image</div>
                        <input class="input" name="image" type="file" accept=".png, .jpg, .jpeg, .svg" />
                        <img id=${'preview-' + awardee.id} class="border border-radius" style="max-height:300px;max-width:300px;" src=${'/bucket/awardee/' + awardee.id} />
                    </label>
                </div>
            </form>
            `)}
        </div>

    </div>
    `
}
