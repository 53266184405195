import { component, html } from './deps';

export default class Donate extends component {
    static tag = 'x-donate';

    async connected () {
        document.title = 'Donate - Tucson Nurses Week Foundation'
    }

    render = () => html`

    <div class="container padding-m background-primary">
        <div class="margin-vertical-l">
            <h1 class="display-title color-white">Donate</h1>
            <hr class="divider background-white">
            <p class="lead lead-s margin-bottom-m color-white">Consider a donation to Tucson Nurses Week Foundation by filling out form below.</p>
        </div>
    </div>

    <div class="container padding-m">
        <h1>Online Donation</h1>
        <p>Tucson Nurses' Week Foundation is a charitable organization that promotes and recognizes outstanding Nursing practices in the greater Tucson area. Everything we do is made possible by your generosity. We host, annually, an educational Conference and Healthy Fair as well as a Gala to celebrate our Tucson Nurses.</p>
        <p>Please consider becoming a sponsor of Tucson Nurses' Week! In addition to any donation of your preference, we have many tiers of sponsorship that we encourage you or your organization to consider. All sponsors are recognized on our site, on our social media channels, and at the yearly festivities to show our appreciation of their support.</p>
        <a class="button button-primary" href="/sponsor">Learn More - Become a Sponsor</a>
        <p>In addition to these, we gladly accept donations of any amount. All monies go directly into paying for the events and supporting Tucson Nurses' Week Foundation. Fabulous 50 Alumni donations go directly to paying for student lunches at the Conference and Healthy Fair as well as the Clinical Nursing Research Grant.</p>
    </div>

    <div class="container padding-m">
        <h2>Donation Form</h2>
        <hr>
        <x-form></x-form>
    </div>

    `

}