import { component, html } from './deps';

export default class AdminPreview extends component {
    static tag = 'x-admin-preview';

    id: string;

    async connected() {
        const id = new URL(location.href).searchParams.get('id');
        this.id = id ?? '';
    }

    render = () => html`
    <div class="container">

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Admin Preview</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white"></p>
            </div>
        </div>

        <div class="flex flex-row justify-content-space-between padding-s">
            <div class="font-size-xl">
                <a href=${'/admin/form?id=' + this.id} class="button button-border button-primary">
                    <span class="material-symbols-outlined">arrow_back</span>
                </a>
            </div>
        </div>

        <div class="flex flex-column justify-content-flex-end align-items-flex-end">
            <div class="padding-xxs color-white background-tertiary">Note this is a preview.</div>
        </div>

        <div class="flex flex-column justify-content-center align-items-center">
        </div>

        <x-form></x-form>

    </div>
    `

}
