import { component, html } from './deps';

export default class Footer extends component {
    static tag = 'x-footer';

    render = () => html`
    <div class="quote flex-justify-center">
        <blockquote class="container">
            <p>"I am of certain convinced that the greatest heroes are those who do their duty in the daily grind of domestic affairs whilst the world whirls as a maddening dreidel."</p>
            <cite>Florence Nightingale</cite>
        </blockquote>
    </div>
    <div style="background-color:#262626; color:white;">
        <div class="flex container padding-m">
            <div class="flex flex-column padding-xs">
                <h3>About</h3>
                <hr>
                <p>Tucson Nurses Week Foundation is a 501©(3) tax exempt organization located in Tucson, Arizona.</p>
                <a class="button button-white button-border" href="/bucket/global/brochure" target="_blank" rel="noopener">Our Brochure</a>
            </div>
            <div class="flex flex-column padding-xs">
                <h3>Links</h3>
                <hr>
                <nav>
                    <ul>
                        <li><a href="/admin">Admin</a></li>
                        <li><a href="/about">About Tucson Nurses Week</a></li>
                    </ul>
                </nav>
            </div>
            <div class="flex flex-column padding-xs">
                <h3>Contact</h3>
                <p>PO Box 65085, Tucson, AZ 85728</p>
                <p><a href="mailto:info@tucsonnursesweekfoundation.org">info@tucsonnursesweekfoundation.org</a></p>
            </div>
        </div>
        <div class="flex align-items-center justify-content-center padding-xs">
            <p>Copyright 2023 Tucson Nurses Week Foundation</p>
        </div>
    </div>
    `

}