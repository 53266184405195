import { component, html } from './deps';

export default class About extends component {
static tag = 'x-about';

async connected() {

}

render = () => html`

<div class="container padding-m background-primary">
    <div class="margin-vertical-l">
        <h1 class="display-title color-white">Who We Are.</h1>
        <hr class="divider background-white">
        <p class="lead lead-s margin-bottom-m color-white">A little about the Tucson Nurses Week Foundation.</p>
    </div>
</div>

<div class="container padding-m">
    <div class="flex-justify">
        <div class="flex flex-column padding-s">
            <img src="/images/featured.png" alt="Featured For Tucson Nurses Week" />
        </div>
        <div class="flex flex-1 flex-column justify-content-center">
            <p>The Tucson Nurses' Week Foundation began in July 1994 when a group of leading Tucson Nurses came together to pursue a vision for a citywide celebration of Nurses Week. The goals of the Foundation and the Nurses Week events are to enhance education and research among Tucson-area Nurses and to celebrate excellence by recognizing Nurses in a manner commensurate with the roles Nurses play in the health of the community. This recognition process seeks Nurses in all settings and all types of Nursing practice. In the past, it was rare for the general public to hear about the accomplishments of Nurses who are responding daily to the health-care needs of the Tucson community.</p>

            <p>The Tucson Nurses Week Foundation is a nonprofit, 501©(3) organization. The Foundation is committed to sharing innovative health-care practices, caring for the community, and celebrating Nursing as a learning profession.</p>
        </div>
    </div>


    <p>During annual Tucson Nurses Week events, the Foundation recognizes, publicizes, and supports the accomplishments, innovations, and contributions of Nurses to the health of our community. The Foundation’s emphasis is on ALL Nurses as each provides the day-to-day health care to the citizens of Tucson. Fifty> of these outstanding Nurses are honored annually. The first group of Fabulous 50 Nurses was honored in 1995.</p>

    <p>Each year, with the support and leadership of the American Nurses Association, one week is set aside to recognize and celebrate Nurses across the country. This week concludes on May 12, the birthday of Florence Nightingale, the founder of modern Nursing.</p>

    <p>In 1994 during a period of massive change and turmoil in the profession of Nursing, David Przestrzelski, RN, MSN, had a dream. He recognized that Nurses were feeling devalued by administration, and this was particularly evident during Nurses Week. Large institutions gave their Nurses mere “token gifts” in appreciation for their dedication/service/care of their patients. Przestrzelski’s dream was to change the way Nurses Week was celebrated in the Tucson area.</p>

    <p>Being a man of action, Przestrzelski gathered a group of individuals from hospitals, patient-care clinics, the military, and institutions of higher learning to help him realize that dream. Many of these individuals were Nurse Executives and all were leaders in their own institutions. These individuals became the Tucson Nurses Week Foundation Board of Directors with David Przestrzelski as the chairperson. In 1995, Tucson Nurses celebrated their professionalism in the first citywide event. This celebration emphasized professionalism, education, research, and personal vitality. The theme of this event “Showcase of Tucson Nurses: Sharing, Caring, and Celebrating” continues to be an event that emphasizes professionalism, education, research, and personal vitality.</p>

    <p>Florence Nightingale once said, “There are two classes of people in the world, those who take the best and enjoy it and those who wish for something better and try to create it.” Przestrzelski and the first Tucson Nurses Week Foundation board epitomize the latter category of people. In that spirit, the Board chose the distinctive Florence Nightingale logo. The image was selected in 1995 in honor of the 175th anniversary of Florence Nightingale’s birthday and it is reproduced with permission from the London Florence Nightingale Museum.</p>

    <p>Over the years, the format of the CARING, SHARING, AND CELEBRATING Showcase of Tucson Nurses has remained the same. Chairpersons change, the members of the Board of the Foundation change, names of the Fabulous 50 Nurses change, but the spirit remains the same: recognize, publicize, and support innovations, accomplishments, and contributions of Nurses in the Tucson area.</p>

</div>

`

}