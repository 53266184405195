import { component, html } from './deps';
import post from './post';

export default class SignIn extends component {
    static tag = 'x-sign-in';

    async submit(e) {
        e.preventDefault();
        const target = e.target;
        const data = Object.fromEntries(new FormData(target));
        target.reset();
        const user = await post('/sign-in', data);
        localStorage.setItem('user', JSON.stringify(user));
        location.assign('/admin');
    }

    render = () => html`
    <div class="container">


        <div class="font-size-xxl margin-vertical-l">Sign In</div>

        <form onsubmit=${this.submit}>
            <div class="field">
                <label for="username">Username</label>
                <input id="username" name="username" type="username" placeholder="Username" autocomplete="username" />
            </div>
            <div class="field">
                <label for="password">Password</label>
                <input id="password" name="password" type="password" placeholder="Password" autocomplete="current-password" />
            </div>
            <div class="field">
                <button type="submit" class="button button-primary button-border">Sign In</button>
            </div>
        </form>

    </div>
    `

}