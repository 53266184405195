import Any from './any';
import Root from './root';
import About from './about';
import Events from './events';
import Header from './header';
import Footer from './footer';
import Sponsor from './sponsor';
import Nominate from './nominate';
import Registration from './registration';
import PastAwardees from './past-awardees';

import SignIn from './sign-in';

import Admin from './admin';
import AdminPage from './admin-page';
import AdminForm from './admin-form';
import AdminResult from './admin-result';
import AdminPreview from './admin-preview';
import AdminAwardees from './admin-awardees';
import AdminSponsors from './admin-sponsors';

import Form from './form';
import Dynamic from './dynamic';
import Donate from './donate';

(async () => {

    const header = document.querySelector('header') as HTMLElement;
    const footer = document.querySelector('footer') as HTMLElement;
    const main = document.querySelector('main') as HTMLElement;

    Form.define();

    if (location.pathname === '/') {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Root.create());
        footer.replaceChildren(await Footer.create());
    } else if (location.pathname === '/admin') {
        main.replaceChildren(await Admin.create());
    } else if (location.pathname === '/admin/page') {
        main.replaceChildren(await AdminPage.create());
    } else if (location.pathname === '/admin/form') {
        main.replaceChildren(await AdminForm.create());
    } else if (location.pathname === '/admin/result') {
        main.replaceChildren(await AdminResult.create());
    } else if (location.pathname === '/admin/preview') {
        main.replaceChildren(await AdminPreview.create());
    } else if (location.pathname === '/admin/awardees') {
        main.replaceChildren(await AdminAwardees.create());
    } else if (location.pathname === '/admin/sponsors') {
        main.replaceChildren(await AdminSponsors.create());
    } else if (location.pathname === '/sign-in') {
        main.replaceChildren(await SignIn.create());
    } else if (
        location.pathname === '/nominate/inspirational-mentor' ||
        location.pathname === '/nominate/certifications-grant' ||
        location.pathname === '/nominate/alex-stuetze' ||
        location.pathname === '/nominate/fabulous-50' ||
        location.pathname === '/nominate/top-5'
    ) {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Nominate.create());
        footer.replaceChildren(await Footer.create());
    } else if (
        location.pathname === '/registration/conference' ||
        location.pathname === '/registration/speaker' ||
        location.pathname === '/registration/poster' ||
        location.pathname === '/registration/vendor' ||
        location.pathname === '/registration/gala' ||
        location.pathname === '/registration/bio'
    ) {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Registration.create());
        footer.replaceChildren(await Footer.create());
    } else if (location.pathname === '/donate') {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Donate.create());
        footer.replaceChildren(await Footer.create());
    } else if (location.pathname === '/sponsor') {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Sponsor.create());
        footer.replaceChildren(await Footer.create());
    } else if (location.pathname === '/about') {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await About.create());
        footer.replaceChildren(await Footer.create());
    } else if (location.pathname === '/events') {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Events.create());
        footer.replaceChildren(await Footer.create());
    } else if (location.pathname === '/dynamic') {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Dynamic.create());
        footer.replaceChildren(await Footer.create());
    } else if (location.pathname === '/past-awardees') {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await PastAwardees.create());
        footer.replaceChildren(await Footer.create());
    } else {
        header.replaceChildren(await Header.create());
        main.replaceChildren(await Any.create())
        footer.replaceChildren(await Footer.create());
    }

})();