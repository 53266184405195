import { Page, Part } from '../database/page';
import { component, html } from './deps';
import post from './post';

const compile = (parts: Part[]) => {
    return parts.map(part => {
        if (part.name === 'section') {
            return html`<section>${part.parts ? compile(part.parts) : ''}</section>`;
        } else if (part.name === 'h2') {
            return html`<h2>${part.parts ? compile(part.parts) : ''}</h2>`;
        } else if (part.name === 'h3') {
            return html`<h3>${part.parts ? compile(part.parts) : ''}</h3>`;
        } else if (part.name === 'p') {
            return html`<p>${part.parts ? compile(part.parts) : ''}</p>`;
        } else if (part.name === 'img') {
            return html`<img src=${part.src ?? ''} />`;
        } else if (part.name === '#text') {
            return part.text ?? '';
        } else {
            return '';
        }
    });
};

export default class Dynamic extends component {
    static tag = 'x-dyanmic';

    page: Page = { id: '', path: '', title: '', description: '', parts: [] };

    async connected() {
        const { pathname } = new URL(location.href);
        this.page = await post<Page>('/page', { pathname });
    }

    render = () => html`

    <div class="container padding-m background-primary">
        <div class="margin-vertical-l">
            <h1 class="display-title color-white">${this.page.title}</h1>
            <hr class="divider background-white">
            <p class="lead lead-s margin-bottom-m color-white">${this.page.description}</p>
        </div>
    </div>

    <div class="container padding-m">
        ${compile(this.page.parts)}
    </div>

    `

}