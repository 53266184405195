import { component, html } from './deps';

export default class Any extends component {
    static tag = 'x-any';

    render = () => html`

        <h1>Not found</h1>

	`

}