import dataUriToObjectUrl from './data-uri-to-object-url';
import { component, html } from './deps';
import post from './post';

export default class About extends component {
    static tag = 'x-about';

    brochureObjectUrl = '';
    speakerImageObjectUrl = '';

    global = {
        year: '',
        month: '',
        brochure: '',
        conferenceDay: '',
        galaDay: '',
        galaLocation: '',
        galaLink: '',
        speakerTitle: '',
        speakerBio: '',
        speakerImage: '',
    };

    async connected() {
        this.global = await post('/global');
        this.brochureObjectUrl = await dataUriToObjectUrl(this.global.brochure);
        this.speakerImageObjectUrl = await dataUriToObjectUrl(this.global.speakerImage);
    }

    render = () => html`

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Events</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white">Conference Healthy Fair and Gala.</p>
            </div>
        </div>

        <div class="container padding-xs">

            <div class="flex-justify padding-xs">
                <div class="flex flex-column padding-s">
                    <img width="300" src="/bucket/global/speaker" alt="speaker image" />
                </div>
                <div class="flex flex-1 flex-column justify-content-center">
                    <h2>Conference and Healthy Fair ${this.global.month} ${this.global.conferenceDay}, ${this.global.year}</h2>
                    <p>The Showcase of Tucson Nurses is a sharing educational conference where southern Arizona Nurses exchange innovative health care ideas during a two-part continuing education offering.</p>
                    <p>As a part of the Nurses Week Celebration, this program is a tribute to southern Arizona Nurses who respond daily to the health care needs of the community.</p>
                    <p>Annually more than two hundred Nurses from southern Arizona, educators, staff Nurses, administrators, clinical nurse specialists, nurse practitioners, researchers, and other practicing professionals from a variety of disciplines, attend the Showcase to learn about current Nursing research and practice, and network with their colleagues.</p>
                    <p>The focus of the Healthy Fair and Conference is to encourage Nurses to explore and experience varied ways of caring for themselves and their own families through education and other modalities. It is also an opportunity for Nurses to share special interests with their peers through arts, self-care, and products. Vendors often display jewelry, uniforms, drug information, vaccine information, scholarly publications, massage therapy, and many other interesting, informative, and fun exhibits for Nurses during the annual conference.</p>
                    <a class="button button-primary" href="/registration/conference">Registration</a>
                </div>
            </div>

            <!-- <div class="flex-justify padding-xs">
                <div class="flex flex-column padding-s">
                    <img width="300" src="https://res.cloudinary.com/dbc2wlvk8/image/upload/c_fill,h_420,w_300/v1712110365/Ken_Pic_ic2fxu.jpg" alt="speaker image" />
                </div>
                <div class="flex flex-1 flex-column justify-content-center">
                    <h2>Keynote Speaker</h2>
                    <p>Kenneth W. Dion is an internationally recognized entrepreneur, inventor, scholar, speaker, and a 40-year veteran of the healthcare industry. He is a registered nurse and holds master's degrees in business administration and nursing. Dion earned his BS in nursing from the University ofCentral Florida, his MS in nursing, his MBA, and his doctorate, all from the University of Texas at Austin. He is best known for his contributions to business start-ups in information systems to support healthcare organizations. For more than a decade, he was the founder and chief executive officer of Decision Critical, Inc., based in Austin, TX. Prior to joining the faculty at Johns Hopkins University, where he was the inaugural assistant dean for business innovation and strategic relationships, Dion was vice president and chief of nursing informatics at Healthstream, Inc., based in Nashville, TN. Dion is also a fellow of the American Academy of Nursing, Sigma Theta Tau International, where he served as treasurer and president, and the Royal College of Surgeons of Ireland.</p>
                </div>
            </div> -->

            <div class="flex-justify padding-xs">
                <div class="flex flex-column padding-s">
                    <img width="300" src="https://res.cloudinary.com/dbc2wlvk8/image/upload/c_scale,fl_any_format.lossy,w_400/v1477689320/TNWF/gala.jpg" alt="gala event image" />
                </div>
                <div class="flex flex-1 flex-column justify-content-center">
                    <h2>Nurses Week Gala ${this.global.month} ${this.global.galaDay}, ${this.global.year}</h2>
                    <p>A formal dance celebrating Nursing, honoring Tucson Fabulous 50 Nurses and presenting the recipient of the annual Clinical Nursing Practice Project Award. Enjoy celebrating, dancing and socializing throughout the evening.</p>
                    <p>
                        <strong>Date: </strong><span>${this.global.month} ${this.global.galaDay}, ${this.global.year}</span><br>
                        <strong>Group Rates Available: </strong><span>5/7/25 - 5/13/25</span><br>
                        <strong>Guest Room Rates: </strong><span>Starting from $159 per night. Day of event complementary self parking or valet parking.</span><br>
                        <strong>Discounts: </strong><span>10% off spa. Waived resort fees.</span><br>
                        <strong>Location: </strong><span>${this.global.galaLocation}</span><br>
                    </p>
                    <p>All reservations must be guaranteed with a valid credit card. This card will be charged in the event of a cancellation, no-show or deposit when warranted. Group rates are subject to availability.</p>
                    <p>Hotel cancellation policy requires guests to cancel by 4pm local time 72 hours before arrival to avoid a penalty fee of 1 nights' room and tax.</p>
                    <p>Guests can also call to make reservations at +1 (800) 235-6397, and mention the Tucson Nurses Week</p>
                    <p>For special TNWF pricing use this link <a class="button" href="${this.global.galaLink}" target="_blank">Reserve</a></p>
                    <a class="button button-primary" href="/registration/gala">Gala</a>
                </div>
            </div>

        </div>

    `

}
