
// body: !body ||
//     body instanceof URLSearchParams ||
//     body instanceof ReadableStream ||
//     body instanceof ArrayBuffer ||
//     body instanceof FormData ||
//     body instanceof Blob ||
//     typeof body === 'string'
//     ? body : JSON.stringify(body)

export default async <T>(url: string | URL, body?: File | Record<any, any> | undefined): Promise<T> => {

    const response = await fetch(url, {
        method: 'POST',
        body:
            body instanceof File ? body :
                body ? JSON.stringify(body) :
                    undefined
    });

    if (response.status !== 200) {
        const message = `${response.status} ${response.statusText}\n${await response.text()}`;
        alert(message);
        throw new Error(message);
    }

    return response.json() as T;
}