import { Result } from '../database/result';

export default function (results: Result[]): string {
    let data = '';

    const keys: string[] = ['"Created"', '"Name"', '"Phone"', '"Email"', '"Charge"', '"Amount"'];

    for (const result of results) {
        const values: (string | number | boolean)[] = [
            `"${new Date(result.created).toLocaleString()}"`,
            `"${result.name}"`,
            `"${result.phone}"`,
            `"${result.email}"`,
            `"${result.charge ?? ''}"`,
            `"${result.amount ?? ''}"`,
        ];

        for (const item of result.items) {
            let key = `${item.name ?? ''}`;
            let value = `${item.value ?? ''}`;

            key = key.replace(/"/g, '\\"');
            key = `"${key}"`;

            value = value.replace(/"/g,'\\"');
            value = `"${value}"`;

            if (!keys.includes(key)) keys.push(key);

            const index = keys.indexOf(key);
            values[ index ] = value;
        }

        data += values.join(',');
        data += '\n';
    }

    return keys.join(',') + '\n' + data;
}
