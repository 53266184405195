export default async (url: string | URL) => {

    const response = await fetch(url, { method: 'GET' });

    if (response.status !== 200) {
        const message = `${response.status} ${response.statusText}\n${await response.text()}`;
        alert(message);
        throw new Error(message);
    }

    return response.json();
}