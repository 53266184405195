import { Sponsor } from '../database/sponsor';
import { component, html } from './deps';
import base64 from './base64';
import post from './post';

export default class AdminSponsors extends component {
    static tag = 'x-admin-sponsors';

    sponsors: Sponsor[] = [];

    async input(e, sponsor) {
        const target = e.target as HTMLInputElement;
        const name = target.name;
        const type = target.type;
        let value;

        if (type === 'file') {
            const file = target.files?.[ 0 ];
            (this.querySelector('#preview-' + sponsor.id) as any).src = URL.createObjectURL(file as File);
            value = await base64(file);
        } else if (type === 'number') {
            value = target.valueAsNumber;
        } else {
            value = target.value;
        }

        sponsor[name] = value;
    }

    async submit(e, sponsor) {
        e.preventDefault();

        if (sponsor.id) {
            await post('/admin/sponsor/update', sponsor);
        } else {
            await post('/admin/sponsor/create', sponsor);
        }

        this.sponsors = await post('/admin/sponsor/search', { year: this.yearSelected });
    }

    async confirm(sponsor) {
        const result = prompt(`Are you sure you want to Remove the Sponsor ${sponsor.name}?`);
        if (result !== 'yes') return;
        await post('/admin/sponsor/remove', sponsor);
        this.sponsors = await post('/admin/sponsor/search');
    }

    add() {
        this.sponsors.unshift({
            id: '',
            updated: 0,
            created: 0,
            name: '',
            type: '',
            year: this.yearSelected,
        });
    }

    yearSelected: number = new Date().getFullYear();

    async yearFilter(e) {
        this.yearSelected = Number(e.target.value);
        this.sponsors = await post('/admin/sponsor/search', { year: this.yearSelected });
    }

    async connected() {
        this.sponsors = await post('/admin/sponsor/search', { year: this.yearSelected });
    }

    render = () => html`
    <div class="container">

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Admin Sponsors Manager</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white"></p>
            </div>
        </div>

        <div class="flex flex-row justify-content-space-between padding-s">
            <div class="font-size-xl">
                <a href="/admin" class="button button-border button-primary">
                    <span class="material-symbols-outlined">dashboard</span>
                    <span>Dashboard</span>
                </a>
            </div>
            <div class="font-size-xl">
                <button class="button" onclick=${this.add}>
                    <span class="material-symbols-outlined">add</span>
                    <span>Add Sponsor</span>
                </button>
            </div>
        </div>

        <div class="flex flex-row padding-s">
            <label class="select" for="yearSelect"> Year
                <select id="yearSelect" value=${this.yearSelected} onchange=${e => this.yearFilter(e)}>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </label>
        </div>

        <div class="padding-m">
            ${this.sponsors.map(sponsor => html`
            <form
                class="flex flex-row flex-wrap align-items-flex-start"
                oninput=${e => this.input(e, sponsor)}
                onsubmit=${e => this.submit(e, sponsor)}
            >
                <div class="flex flex-column align-items-flex-start">
                    <div class="flex flex-row">
                        <div class="flex flex-column">
                            <label class="field">
                                <div>Name</div>
                                <input class="input" name="name" type="text" value=${sponsor.name} required/>
                            </label>
                            <label class="field">
                                <div>Type</div>
                                <select name="type" class="input" value=${sponsor.type} required>
                                    <option value="" selected></option>
                                    <option value="florence nightingale">Florence Nightingale</option>
                                    <option value="platinum">Platinum</option>
                                    <option value="diamond">Diamond</option>
                                    <option value="keynote speaker sponsor">Keynote Speaker Sponsor</option>
                                    <option value="photo sponsor">Photo Sponsor</option>
                                    <option value="emerald">Emerald</option>
                                    <option value="ruby">Ruby</option>
                                    <option value="turquoise">Turquoise</option>
                                    <option value="friend of nursing">Friend Of Nursing</option>
                                </select>
                            </label>
                        </div>
                        <div class="flex flex-column">
                            <label class="field">
                                <div>Year</div>
                                <select name="year" value=${sponsor.year} onchange=${e => sponsor.year === e.target.valueAsNumber} required>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div class="field flex flex-row">
                        <button class="button success margin-xs" type="submit">
                            <span class="material-symbols-outlined">save</span>
                            <span>Save</span>
                        </button>
                        <button class="button error margin-xs" type="button" onclick=${() => this.confirm(sponsor)}>
                            <span class="material-symbols-outlined">delete</span>
                            <span>Remove</span>
                        </button>
                    </div>
                </div>
                <div class="flex flex-auto">
                    <label class="field" >
                        <div>Image</div>
                        <input class="input" name="image" type="file" accept=".png, .jpg, .jpeg, .svg" />
                        <img id=${'preview-' + sponsor.id} class="border border-radius" style="max-height:300px;max-width:300px;" src=${'/bucket/sponsor/' + sponsor.id} />
                    </label>
                </div>
            </form>
            `)}
        </div>

    </div>
    `
}
