import { Awardee } from '../database/awardee';
import { component, html } from './deps';
import post from './post';

export default class Root extends component {
	static tag = 'x-root';

	global = {
		year: '',
		month: '',
		conferenceDay: '',
		galaDay: '',
		galaLocation: '',
		galaLink: '',
	};

	sponsors = [
		{
			link: 'https://jobs.tmcaz.com/',
			image: 'https://res.cloudinary.com/dbc2wlvk8/image/upload/v1650994150/TMC-060_Nurses_Week_Sponsor_digital_banner_1_ylxovx.jpg',
			alt: 'Tmc banner ad'
		},
		{
			link: 'https://jobs.northwestmedicalcenter.com/nurses',
			image: 'https://res.cloudinary.com/dbc2wlvk8/image/upload/fl_any_format.lossy/v1493139115/TNWF/Ruby-up/NMC-fab50WebAd2017_3.jpg',
			alt: 'NMC banner ad'
		}
	];

	sponsor = {
		link: 'https://jobs.tmcaz.com/',
		image: 'https://res.cloudinary.com/dbc2wlvk8/image/upload/v1650994150/TMC-060_Nurses_Week_Sponsor_digital_banner_1_ylxovx.jpg',
		alt: 'Tmc banner ad'
	};

	awardees: Record<string, Array<Awardee>> = {
		'alex stuetze': [],
		'top 5 awardees': [],
		'fabulous 50 awardees': [],
		'most inspirational mentor': [],
	};

	async connected() {
		this.global = await post('/global');

		const result = await post<Awardee[]>('/awardees', { year: Number(this.global.year) - 1 });

		result.forEach(awardee => this.awardees[awardee.type].push(awardee));

		let index = 1;

		setInterval(() => {
			this.sponsor = this.sponsors[index];
			index = (index + 1) % this.sponsors.length;
		}, 5000);
	}

	render = () => html`
	<style>
		img {
			width: 200px;
			height: 250px;
			object-fit: cover;
			background: lightgray;
		}
	</style>
	<div class="background-primary">
		<div class="container padding-m">
			<div class="margin-vertical-l">
				<h2 class="display-title color-white">${this.global.year} Tucson Nurses Week Foundation Conference</h2>
				<hr class="divider background-white">
				<p class="lead lead-s color-white">
					Save the date for <strong>The Conference on ${this.global.month}, ${this.global.conferenceDay}</strong> and <strong>The Gala on ${this.global.month}, ${this.global.galaDay}</strong>.
				</p>
				<div class="flex flex-row flex-wrap">
					<div class="margin-s"><a class="button button-white" href="/registration/conference">Register for Conference</a></div>
					<div class="margin-s"><a class="button button-white" href="/registration/gala">Register for Gala</a></div>
					<div class="margin-s"><a class="button button-white" href="${this.global.galaLink}" target="_blank">Hotel - Special Room Rates</a></div>
				</div>
			</div>
			<div class="margin-vertical-l">
				<h2 class="display-title color-white">Nominate a Nurse Today</h2>
				<hr class="divider background-white">
				<p class="lead lead-s color-white">Nominate a nurse for Fab 50, Top 5, Most Inspirational Mentor, Alex Stuetze Award, and Certifications Grant.</p>
				<div class="flex flex-row flex-wrap">
					<div class="margin-s"><a class="button button-white" href="/nominate/fabulous-50">Fab 50</a></div>
					<div class="margin-s"><a class="button button-white" href="/nominate/top-5">Top 5</a></div>
					<div class="margin-s"><a class="button button-white" href="/nominate/inspirational-mentor">Most Inspirational Mentor</a></div>
					<div class="margin-s"><a class="button button-white" href="/nominate/alex-stuetze">Alex Stuetze Award</a></div>
					<div class="margin-s"><a class="button button-white" href="/nominate/certifications-grant">Certifications Grant</a></div>
				</div>
			</div>
			<div class="margin-vertical-l">
				<h2 class="display-title color-white">Speaker, Poster, Vendor Registration</h2>
				<hr class="divider background-white">
				<p class="lead lead-s color-white">Register as a speaker, poster, or a vendor.</p>
				<div class="flex flex-row flex-wrap">
					<div class="margin-s"><a class="button button-white" href="/registration/speaker">Speaker</a></div>
					<div class="margin-s"><a class="button button-white" href="/registration/poster">Poster</a></div>
					<div class="margin-s"><a class="button button-white" href="/registration/vendor">Vendor</a></div>
				</div>
			</div>
		</div>
	</div>

	<div class="container padding-m">

		<div class="flex flex-wrap">
			<div class="flex padding-s">
				<img style="width:300px; min-width:300px;" src="/images/featured.png" alt="Tucson Nurses Week Featured Image" loading="lazy" />
			</div>
			<div class="flex flex-1 flex-column justify-content-center">
				<p>The Tucson Nurses Week Foundation began in July 1994 when a group of Tucson nurse leaders came together to pursue a vision for a citywide celebration of Nurses Week. The goals of the Foundation and the Nurses Week events are to enhance education and research among Tucson-area Nurses and to celebrate excellence by recognizing Nurses in a manner commensurate with the roles Nurses play in the health of the community. This recognition process seeks Nurses in all settings and all types of Nursing practice.</p>

				<a href="/about" class="button button-primary">Learn More - Tucson Nurses Week Foundation</a>
			</div>
		</div>

		<h2>2024 Winners</h2>
		<div class="flex flex-row margin-vertical-m">
			<div class="flex-column">
				<h3>Fabulous 50 Awardees</h3>
				<hr>
			</div>
		</div>
		<div class="flex flex-row justify-content-space-around flex-wrap">
			${this.awardees[ 'fabulous 50 awardees' ].map(awardee => html`
				<div class="flex flex-column justify-content-center align-items-center">
					<h4>${awardee.name}</h4>
					<img class="awardee-img" src=${'/bucket/awardee/' + awardee.id} alt="awardee" loading="lazy"/>
					<p>${awardee.facility}</p>
				</div>
			`)}
		</div>

		<div class="flex flex-row margin-vertical-m">
			<div class="flex-column">
				<h3>Top 5 Awardees</h3>
				<hr>
			</div>
		</div>
		<div class="flex flex-row justify-content-space-around flex-wrap">
			${this.awardees[ 'top 5 awardees' ].map(awardee => html`
				<div class="flex flex-column justify-content-center align-items-center">
					<h4>${awardee.name}</h4>
					<img class="awardee-img" src=${'/bucket/awardee/' + awardee.id} alt="awardee" loading="lazy"/>
					<p>${awardee.facility}</p>
				</div>
			`)}
		</div>

		<div class="flex flex-row margin-vertical-m">
			<div class="flex-column">
				<h3>Most Inspirational Mentor</h3>
				<hr>
			</div>
		</div>
		<div class="flex flex-row justify-content-space-around flex-wrap">
			${this.awardees[ 'most inspirational mentor' ].map(awardee => html`
				<div class="flex flex-column justify-content-center align-items-center">
					<h4>${awardee.name}</h4>
					<img class="awardee-img" src=${'/bucket/awardee/' + awardee.id} alt="awardee" loading="lazy"/>
					<p>${awardee.facility}</p>
				</div>
			`)}
		</div>

		<div class="flex flex-row margin-vertical-m">
			<div class="flex-column">
				<h3>Alex Stuetze</h3>
				<hr>
			</div>
		</div>
		<div class="flex flex-row justify-content-space-around flex-wrap">
			${this.awardees[ 'alex stuetze' ].map(awardee => html`
				<div class="flex flex-column justify-content-center align-items-center">
					<h4>${awardee.name}</h4>
					<img class="awardee-img" src=${'/bucket/awardee/' + awardee.id} alt="awardee" loading="lazy"/>
					<p>${awardee.facility}</p>
				</div>
			`)}
		</div>

	</div>

	`;

}